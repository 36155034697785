import React from "react";
import { Root } from "./style";

import Layout from "components/Layout/Dashboard";
import FlipBox from "components/Common/FlipBox";
import SiteData from "components/Common/SiteData";
import Transactions from "components/Common/Transactions";

import { CLAIM_INTERVAL, formatNumber, inWindow } from "utils";

import { useDashboard } from "dashboard";
import { useWeb3 } from "web3";

const DashboardRewards = ({ location }) => {
  const { claimableBNB, rewarded } = useDashboard();
  const { bsc } = useWeb3();

  return (
    <Layout location={location}>
      {inWindow() ? (
        <>
          <Root data-row-count="3">
            <FlipBox>
              <div>
                <h4 className="bnb">Total BNB Rewarded</h4>
                <strong>{formatNumber(rewarded, 3)} BNB</strong>
                <small>${formatNumber(rewarded * bsc.bnbUsdPrice, 2)}</small>
              </div>
              <div>
                <SiteData
                  className="info"
                  path="dashboard.rewardsTotalBnbRewarded"
                />
              </div>
            </FlipBox>
            <FlipBox>
              <div>
                <h4 className="bnb">Monthly forecast</h4>
                <strong>
                  {formatNumber((30 / CLAIM_INTERVAL) * claimableBNB, 3)} BNB
                </strong>
                <small>
                  $
                  {formatNumber(
                    (30 / CLAIM_INTERVAL) * claimableBNB * bsc.bnbUsdPrice,
                    2
                  )}
                </small>
              </div>
              <div>
                <SiteData
                  className="info"
                  path="dashboard.rewardsMonthlyForecast"
                />
              </div>
            </FlipBox>
            <FlipBox>
              <div>
                <h4 className="bnb">Yearly forecast</h4>
                <strong>
                  {formatNumber((365 / CLAIM_INTERVAL) * claimableBNB, 3)} BNB
                </strong>
                <small>
                  $
                  {formatNumber(
                    (365 / CLAIM_INTERVAL) * claimableBNB * bsc.bnbUsdPrice,
                    2
                  )}
                </small>
              </div>
              <div>
                <SiteData
                  className="info"
                  path="dashboard.rewardsYearlyForecast"
                />
              </div>
            </FlipBox>
          </Root>
          <Transactions location={location} type="rewards" currency="bnb" />
        </>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default DashboardRewards;
